import * as React from "react";
import {
  ArrayInput,
  BooleanInput,
  SelectInput,
  SimpleFormIterator,
  TextInput
} from 'react-admin';

const OpeningDayInput = (props) => {
  function validateTime(value) {
    const errors = [];

    let split = (value || '').replace(' ', '').replace('h', ':').split(':');

    if (split.length) {
      // Hour must be between 0 & 23
      (split[0] || '') < 24 || errors.push(` Heure incorrecte.`);
      (split[0] || '') >= 0 || errors.push(` Heure incorrecte.`);

      (split[1] || '') < 60 || errors.push(` Minutes incorrectes.`);
      (split[1] || '') >= 0 || errors.push(` Minutes incorrectes.`);
    }

    return errors.length ? errors : null;
  }

  let inlineStyle = {float: 'left', marginLeft: '20px'};

  return (
    <SimpleFormIterator {...props}>
      <SelectInput source="day" label="Jour" style={inlineStyle} choices={[
        {id: 1, name: 'Lundi'},
        {id: 2, name: 'Mardi'},
        {id: 3, name: 'Mercredi'},
        {id: 4, name: 'Jeudi'},
        {id: 5, name: 'Vendredi'},
        {id: 6, name: 'Samedi'},
        {id: 7, name: 'Dimanche'},
      ]}/>
      <BooleanInput source="isExceptionallyClosed" label="Exceptionnellement fermé"
                    style={{...inlineStyle, marginTop: '13px'}}/>
      <ArrayInput {...props} source="openingTimeRanges" label="Horaires">
        <SimpleFormIterator>
          <TextInput source="openedAt" label="Ouvre à" validate={validateTime}
                     style={inlineStyle}/>
          <TextInput source="closedAt" label="Ferme à" validate={validateTime}
                     style={inlineStyle}/>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleFormIterator>
  );
}
export default OpeningDayInput;
