import * as React from 'react';
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import CustomImageInput from "../field/CustomImageInput";

const ProductCategories = {
  List: props => (
    <ListGuesser {...props}>
      <FieldGuesser source="name" label="Nom de la catégorie"/>
    </ListGuesser>
  ),
  Create: props => (
    <CreateGuesser {...props}>
      <InputGuesser source="name" label="Nom de la catégorie"/>
      <CustomImageInput {...props} source="picture" label="Image"/>
      <InputGuesser source="description" label="Description"/>
    </CreateGuesser>
  ),
  Edit: props => (
    <EditGuesser {...props}>
      <InputGuesser source="name" label="Nom de la catégorie"/>
      <CustomImageInput source="picture" label="Image"/>
      <InputGuesser source="description" label="Description"/>
    </EditGuesser>
  )
};

export default ProductCategories;
