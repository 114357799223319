import * as React from 'react';
import {
  CreateGuesser,
  EditGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import {
  ChipField,
  FunctionField,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayField,
  SingleFieldList,
} from 'react-admin';

const inlineStyle = {float: 'left', marginRight: '20px'};
const suffixStyle = {
  float: 'left',
  marginRight: '20px',
  marginTop: '25px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
};

function parseProducts(value) {
  return value.map(v => v['@id'] ?? v);
}

const PromotionalOffers = {
  List: props => (
    <ListGuesser {...props}>
      <EmbeddedPromotionalOffers label="Remise" {...props}/>
      <ArrayField label="Produits" source="products" target="products">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ArrayField>
    </ListGuesser>
  ),
  Create: props => (
    <CreateGuesser {...props}>
      <ReferenceArrayInput label="Produits" source="products" reference="products">
        <SelectArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <InputGuesser source="discountAmount" label="Montant de la remise" style={inlineStyle}/>
      <span style={suffixStyle}>€</span>
      <div style={{clear: 'both', marginLeft: '110px', position: 'relative', top: '-7px'}}>OU</div>
      <InputGuesser source="discountPercentage" label="Pourcentage du prix remisé" style={inlineStyle}/>
      <span style={suffixStyle}>%</span>
      <div style={{clear: 'both'}}/>
    </CreateGuesser>
  ),
  Edit: props => (
    <EditGuesser {...props}>
      <ReferenceArrayInput label="Produits" source="products" reference="products" format={parseProducts}>
        <SelectArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <InputGuesser source="discountAmount" label="Montant de la remise" style={inlineStyle}/>
      <span style={suffixStyle}>€</span>
      <div style={{clear: 'both', marginLeft: '110px', position: 'relative', top: '-7px'}}>OU</div>
      <InputGuesser source="discountPercentage" label="Pourcentage du prix remisé" style={inlineStyle}/>
      <span style={suffixStyle}>%</span>
      <div style={{clear: 'both'}}/>
    </EditGuesser>
  ),
  EmbeddedPromotionalOffers: props => {
    const promotionalOffer = props.record[props.source] || props.record;

    if (promotionalOffer) {
      if (promotionalOffer.discountAmount) {
        return <FunctionField {...props} label="Remise" render={_ => `${promotionalOffer.discountAmount} €`}/>;
      }

      if (promotionalOffer.discountPercentage) {
        return <FunctionField {...props} label="Remise" render={_ => `${promotionalOffer.discountPercentage} %`}/>;
      }
    }

    return <div>Aucune remise</div>;
  }
};

const EmbeddedPromotionalOffers = PromotionalOffers.EmbeddedPromotionalOffers;

export default PromotionalOffers;
