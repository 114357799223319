import {dataProvider} from "../App";
import * as React from "react";
import {ImageInput} from 'react-admin';

class CustomImageInput extends React.Component {
  render() {
    let that = this;
    let images = {};

    function formatImage(v) {
      if (!v) {
        return;
      }

      if (v.then) {
        return v.then(res => {
          console.log(v, res);
          return {'@id': res['@id'], contentUrl: images[res]};
        });
      }

      return v;
    }

    function parseImage(v) {
      if (!v) {
        return;
      }

      if (v instanceof File) {
        return convertFileToBase64(v).then(res => {
            var formData = new FormData();
            formData.append("file", v);

            const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
            var token = localStorage.getItem('token');

            const request = new Request(`${entrypoint}/media_objects`, {
              method: 'POST',
              body: formData,
              headers: new Headers({ 'Authorization': `Bearer ${token}` }),
            });

            return fetch(request)
              .then(response => {
                /** @var Response response */
                if (response.status < 200 || response.status >= 300) {
                  throw new Error(response.statusText);
                }
                console.log(response);
                return response.json().then(data => {
                  // let data = response.json();
                  console.log(data);
                  images[data['@id']] = data.contentUrl;
                  that.props.record[that.props.source] = that.props.record[that.props.source] || {};
                  that.props.record[that.props.source].contentUrl = data.contentUrl;
                  that.props.record[that.props.source]['@id'] = data['@id'];
                  that.forceUpdate();

                  return data['@id'];
                });
              });

            // return dataProvider
            //   .create('media_objects', {...v, data: {...v, file: res}})
            //   .then(res => {
            //     images[res.data.id] = res.data.contentUrl;
            //     that.props.record[that.props.source] = that.props.record[that.props.source] || {};
            //     that.props.record[that.props.source].contentUrl = res.data.contentUrl;
            //     that.props.record[that.props.source]['@id'] = res.data['@id'];
            //     that.forceUpdate();
            //     return res.data.id;
            //   })
        });
      }
    }

    /**
     * Convert a `File` object returned by the upload input into a base 64 string.
     * That's not the most optimized way to store images in production, but it's
     * enough to illustrate the idea of data provider decoration.
     */
    const convertFileToBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file);
      });

    if (this.props.record[this.props.source]) {
      return (
        <ImageInput {...this.props} accept="image/*" parse={parseImage} format={formatImage} label="Image">
          <img alt="" src={this.props.record[this.props.source].contentUrl} style={{maxWidth: '256px'}}/>
        </ImageInput>
      );
    }

    return (
      <ImageInput {...this.props} accept="image/*" parse={parseImage} format={formatImage}>
        <></>
      </ImageInput>
    );
  };
}

export default CustomImageInput;
