import * as React from "react";
import {SelectInput} from 'react-admin';

const ReferenceSelectionInput = props => {
  if (props.input.value) {
    props.input.value = props.input.value['@id'] || props.input.value;
  }

  return <SelectInput {...props} optionText="name"/>;
}
export default ReferenceSelectionInput;
