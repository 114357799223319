import * as React from 'react';
import {
  ListGuesser,
  ShowGuesser
} from "@api-platform/admin";
import {
  ArrayField,
  Datagrid,
  DateField,
  SelectField,
  SingleFieldList,
  TextField,
  FunctionField
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import PromotionalOffers from "./promotionalOffers";

const useSingleFieldListStyles = makeStyles({
  root: {
    display: "block",
    flexWrap: "unset",
    marginTop: 0,
    marginBottom: 0
  },
  link: {
    display: "block",
    pointerEvents: 'none',
    cursor: 'text',
    color: '#000'
  }
});

function CoordinatesField(props) {
  return (
    <div {...props}>
      <TextField {...props} source="recipientName" label="Destinataire"/>
      <br/>
      <TextField {...props} source="phoneNumber" label="Téléphone"/>
      <br/>
      <TextField {...props} source="address" label="Adresse"/>
      <br/>
      <City {...props} source="city" label="Ville"/>
    </div>
  );
}

function StatusField(props) {
  let text = '';

  switch (props.record.status) {
    case 10:
      text = 'En attente de paiment (CB)';
    break;
    case 20:
      text = 'Remboursé';
    break;
    case 30:
      text = 'À livrer';
      if(props.record.paymentMode === 2) {
        text += ' (paiement à la livraison)';
      } else {
        text += ' (payé)';
      }
    break;
    case 40:
      text = 'En livraison';
    break;
    case 50:
      text = 'Livré';
  }
  return (
    <div {...props}>
      {text}
    </div>
  );
}

function City(props) {
  return <SelectField {...props} choices={[
    {id: 1, name: 'Cayenne'},
    {id: 2, name: 'Matoury'},
    {id: 3, name: 'Remire-Montjolly'},
  ]}/>
}

const Carts = {
  Show: props => {
    const classes = useSingleFieldListStyles();

    return (
      <ShowGuesser {...props}>
        <DateField source="deliverAt" label="Livraison le"
                   options={{month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}} locales="fr-FR"/>
        <CoordinatesField {...props} />
        <StatusField {...props} />
        <ArrayField source="products" label="Produits">
          <Datagrid>
            <TextField source="product.name" label="Produit"/>
            <ArrayField source="accompaniments" label="Accompagnements">
              <SingleFieldList classes={classes}>
                <TextField label={false} source="name"/>
              </SingleFieldList>
            </ArrayField>
            <TextField source="quantity" label="Qté"/>
            <TextField source="comment" label="Note"/>
          </Datagrid>
        </ArrayField>
        <FunctionField {...props} label="Total" render={cart => `${cart.totalAmount} €`}/>(
      </ShowGuesser>
    )
  },
  List: props => {
    const classes = useSingleFieldListStyles();

    return (
      <ListGuesser {...props}>
        <StatusField {...props} />
        <DateField source="deliverAt" label="Livraison le"
                   options={{month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}} locales="fr-FR"/>
        <CoordinatesField {...props} />product
        <ArrayField source="products" label="Produits">
          <Datagrid>
            <TextField source="product.name" label="Produit"/>
            <ArrayField source="accompaniments" label="Accompagnements">
              <SingleFieldList classes={classes}>
                <TextField label={false} source="name"/>
              </SingleFieldList>
            </ArrayField>
            <TextField source="quantity" label="Qté"/>
            <TextField source="comment" label="Note"/>
          </Datagrid>
        </ArrayField>
        <FunctionField {...props} label="Total" render={cart => `${cart.totalAmount} €`}/>
      </ListGuesser>
    )
  }
};

export default Carts;
