import * as React from 'react';
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import {ArrayInput} from 'react-admin';
import OpeningDayInput from "../field/OpeningDayInput";

const Partners = {
  List: props => (
    <ListGuesser {...props}>
      <FieldGuesser source="name" label="Nom du partenaire"/>
      <FieldGuesser source="notice" label="Mention à ajouter sur les produits"/>
      <FieldGuesser source="hasShippingFees" label="Facture la livraison"/>
    </ListGuesser>
  ),
  Create: props => (
    <CreateGuesser {...props}>
      <InputGuesser source="name" label="Nom du partenaire"/>
      <InputGuesser source="notice" label="Mention à ajouter sur les produits"/>
      <InputGuesser source="hasShippingFees" label="Facture la livraison"/>
      <ArrayInput source="openingDays" label="Jours d'ouverture">
        <OpeningDayInput/>
      </ArrayInput>
    </CreateGuesser>
  ),
  Edit: props => (
    <EditGuesser {...props}>
      <InputGuesser source="name" label="Nom du partenaire"/>
      <InputGuesser source="notice" label="Mention à ajouter sur les produits"/>
      <InputGuesser source="hasShippingFees" label="Facture la livraison"/>
      <ArrayInput source="openingDays" label="Jours d'ouverture">
        <OpeningDayInput/>
      </ArrayInput>
    </EditGuesser>
  )
};

export default Partners;
